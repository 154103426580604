<template>
    <div class="dosiin_page-min-height">
        <div :class="$style.dosiin_error_page">
            <h1 style=" text-align: center; line-height: 40px; ">
                Rất tiếc! <br> Chúng tôi không thể tìm thấy những gì bạn đang tìm kiếm.
            </h1>   
        </div>
    </div>
</template>

<style module>
    .dosiin_error_page{
        width: 100%;
        max-width: 100%;
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }
</style>