// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._404_dosiin_error_page_2kCn7{width:100%;max-width:100%;height:80vh;display:flex;align-items:center;justify-content:center;flex-direction:column;position:relative}", ""]);
// Exports
exports.locals = {
	"dosiin_error_page": "_404_dosiin_error_page_2kCn7"
};
module.exports = exports;
